<template>
  <div v-if="page_loading">
    <loading :page_loading="page_loading" />
  </div>
  <div v-else>
    <loading :loading="loading" />

    <v-dialog v-model="ShowPhoto" max-width="500">
      <v-card class="ma-0">
        <v-card-text>
          <v-row align="center">
            <v-col cols="12" class="text-center">
              <img class="hidden-md-and-up" :src="ShowPhotoLink" width="300" />
              <img class="hidden-sm-and-down" :src="ShowPhotoLink" width="450" />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn text color="#1976d2" @click="Opentap(ShowPhotoLink)">เปิดแถบใหม่</v-btn>
          <v-spacer></v-spacer>
          <v-btn text color="#1976d2" @click="ShowPhoto = false">ปิด</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- นำทาง -->
    <v-card elevation="0" class="v-card-bottom-30">
      <v-card-title class="body-1">
        สต๊อก
        <v-icon>mdi-chevron-right</v-icon>
        รายงานการเช็คสต๊อก
      </v-card-title>
    </v-card>

    <!-- ค้นหา -->
    <v-card elevation="1" class="v-card-bottom-30">
      <v-card-title>
        <v-row>
          <v-col cols="6">
            <v-icon color="blue" size="24">fa-sliders-h</v-icon>
            <span class="ml-2">ค้นหาข้อมูล</span>
          </v-col>
          <v-col cols="6" align="right">
            <v-btn
              :elevation="1"
              outlined
              color="success"
              class="pa-2 ml-2 mt-1"
              @click="ExportExcel(search)"
            >
              <v-icon size="16" class="pr-1">fa-file-excel</v-icon>
              <span class="fn-12"> Excel </span>
              <span v-if="loadingExcel != 0">({{ loadingExcel }}%)</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-row class="pb-2">
          <v-col class="pb-1" cols="12" md="2" lg="1"> ร้านค้า : </v-col>
          <v-col class="pb-1" cols="12" md="4">
            <v-autocomplete
              v-model="search.shop"
              placeholder="กรุณากรอกร้านค้า"
              :items="search.shopList"
              :item-text="(item) => item.name"
              :item-value="(item) => item.id"
              outlined
              required
              hide-details
              dense
            ></v-autocomplete>
          </v-col>
          <v-col class="pb-1" cols="12" md="2" lg="1"> วันที่ : </v-col>
          <v-col class="pb-1" cols="12" md="2">
            <v-menu
              ref="search_menu_date_to"
              v-model="search.menu_date_to"
              :close-on-content-click="false"
              :return-value.sync="search.date_to"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  :value="computed_search_date_to"
                  @change="(value) => (computed_search_date_to = value)"
                  autocomplete="off"
                  label="เริ่ม"
                  outlined
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  dense
                  hide-details
                  v-on="on"
                >
                </v-text-field>
              </template>
              <v-date-picker
                v-model="search.date_to"
                @input="
                  (search.menu_date_to = false),
                    $refs.search_menu_date_to.save(search.date_to)
                "
                no-title
                class="my-0"
              >
                <!-- :allowed-dates="allowedDates" -->
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col class="pb-1" cols="12" md="2">
            <v-menu
              ref="search_menu_date_from"
              v-model="search.menu_date_from"
              :close-on-content-click="false"
              :return-value.sync="search.date_from"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  :value="computed_search_date_from"
                  @change="(value) => (computed_search_date_from = value)"
                  autocomplete="off"
                  label="สิ้นสุด"
                  outlined
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  dense
                  hide-details
                  v-on="on"
                >
                </v-text-field>
              </template>
              <v-date-picker
                v-model="search.date_from"
                @input="
                  (search.menu_date_from = false),
                    $refs.search_menu_date_from.save(search.date_from)
                "
                no-title
                class="my-0"
              >
                <!-- :allowed-dates="allowedDates" -->
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>

        <v-row class="pb-2">
          <v-col class="pb-1" cols="12" md="2" lg="1"> ชื่อสินค้า : </v-col>
          <v-col class="pb-1" cols="12" md="4">
            <v-text-field
              :value="search.name"
              @change="(value) => (search.name = Trim_value(value))"
              @keyup.enter="load_data()"
              ref="refaddFCName"
              outlined
              dense
              hide-details
              autocomplete="off"
            ></v-text-field>
          </v-col>
          <v-col class="pb-1" cols="12" md="2" lg="1"> ชื่อพนักงาน : </v-col>
          <v-col class="pb-1" cols="12" md="4">
            <v-text-field
              :value="search.staffName"
              @change="(value) => (search.staffName = Trim_value(value))"
              @keyup.enter="load_data()"
              ref="refaddFCName"
              outlined
              dense
              hide-details
              autocomplete="off"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row class="pb-2">
          <v-col class="pb-1" cols="12" md="2" lg="1"> SKU : </v-col>
          <v-col class="pb-1" cols="12" md="4">
            <v-text-field
              :value="search.sku"
              @change="(value) => (search.sku = Trim_value(value))"
              @keyup.enter="load_data()"
              ref="refaddFCName"
              outlined
              dense
              hide-details
              autocomplete="off"
            ></v-text-field>
          </v-col>
          <v-col class="pb-1" cols="12" md="2" lg="1"> ประเภท : </v-col>
          <v-col class="pb-1" cols="12" md="4">
            <v-select
              :value="search.stockType"
              @change="(value) => (search.stockType = value)"
              autocomplete="off"
              :items="[
                { text: 'ทั้งหมด', value: 1 },
                { text: 'สต๊อกจริงกับระบบเท่ากัน', value: 2 },
                { text: 'สต๊อกจริงน้อยกว่าในระบบ', value: 3 },
                { text: 'สต๊อกจริงมากกว่าในระบบ', value: 4 },
              ]"
              item-key="text"
              item-value="value"
              hide-details
              dense
              outlined
            ></v-select>
          </v-col>
        </v-row>

        <v-row class="pb-2">
          <v-col class="pb-1" cols="12" md="2" lg="1">Barcode : </v-col>
          <v-col class="pb-1" cols="12" md="4">
            <v-text-field
              :value="search.barcode"
              @change="(value) => (search.barcode = Trim_value(value))"
              @keyup.enter="load_data()"
              ref="refaddFCName"
              outlined
              dense
              hide-details
              autocomplete="off"
            ></v-text-field>
          </v-col>
          <v-col class="pb-1" cols="12" md="2" lg="1"> </v-col>
          <v-col class="pb-1" cols="12" md="4">
            <v-btn
              :elevation="1"
              class="pa-2 mt-1"
              color="primary"
              dark
              @click="
                (page_Stock = 1), (limits_Stock = 50), (offsets_Stock = 0), load_data()
              "
            >
              <v-icon size="14" class="pr-1">fa-solid fa-magnifying-glass</v-icon> ค้นหา
            </v-btn>
            <vue-excel-xlsx
              id="GetExcel"
              v-show="false"
              :data="datasetExcel"
              :columns="headerExcel"
              :file-name="'ล็อตสินค้า'"
              :sheet-name="'ล็อตสินค้า'"
            >
            </vue-excel-xlsx>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- ข้อมูลทั้งหมด -->
    <v-row class="ma-0 py-1" justify="space-between" align="center">
      <v-col cols="8" class="pa-0">
        <table>
          <td>
            <span class="head-doc"> สินค้า </span> ทั้งหมด
            {{ formatMoney(dataStock_sum) }} แถว
          </td>
        </table>
      </v-col>
      <v-col cols="2" class="pa-0 hidden-xs-only" align="right"> </v-col>
    </v-row>

    <!-- แสดงตาราง -->
    <v-data-table
      :headers="header"
      :items="data_stock_list"
      item-key="id"
      class="elevation-0 packhai-border-table"
      :mobile-breakpoint="0"
      :items-per-page="10000000"
      hide-default-footer
    >
      <template v-slot:item.no="{ item }">
        {{
          gen_num_data_table(data_stock_list.indexOf(item), dataStock_sum, offsets_Stock)
        }}
      </template>
      <template v-slot:item.photoLink="{ item }">
        <img
          v-if="item.photoLink != null"
          :src="item.photoLink"
          @click="OpenPhoto(item.photoLink)"
          class="picsize mt-1"
        />
        <img
          v-else
          src="https://packhaistorage.s3.ap-southeast-1.amazonaws.com/image/WhiteImage.JPG"
          class="picsize mt-1"
        />
      </template>

      <template v-slot:item.name="{ item }">
        <span class="color-gray-13">
          <span class="fn-13" style="font-weight: bold; text-decoration: underline">
            <a @click="gotoStock_InFo(item)">{{ item.productName }}</a></span
          >
          <v-row>
            <v-col>
              <span v-if="item.productCode != null && item.productCode != ''">
                รหัสสินค้า : {{ item.productCode }}</span
              >
              <span
                v-if="
                  item.productCode != null &&
                  item.productCode != '' &&
                  (item.prop1Description || item.prop2Description)
                "
              >
                ,</span
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <span class="color-gray-13"
                >{{ !item.barcode ? "" : "barcode" + " : " }} {{ item.barcode }}
                {{ item.barcode && item.sku ? "," : "" }}
                {{ !item.sku ? "" : "SKU" + " : " }} {{ item.sku }}</span
              >
            </v-col>
          </v-row>
        </span>
      </template>

      <template v-slot:item.systemQuantity="{ item }">
        {{ item.systemQuantity }}
      </template>

      <template v-slot:item.realQuantity="{ item }">
        {{ item.realQuantity }}
      </template>

      <template v-slot:item.diffQuantity="{ item }">
        <span v-if="item.diffQuantity == 0" style="color: #00ff00"
          ><b>{{ item.diffQuantity }}</b>
        </span>
        <span v-if="item.diffQuantity < 0" style="color: #ff0000"
          ><b>{{ item.diffQuantity }}</b>
        </span>
        <span v-if="item.diffQuantity > 0" style="color: #ffa500"
          ><b>{{ item.diffQuantity }}</b></span
        >
      </template>

      <template v-slot:item.created="{ item }">
        {{ set_format_date_time2(item.created) }}
      </template>
    </v-data-table>

    <!-- เปลี่ยนหน้า -->
    <v-row
      v-if="pageLength_Stock > 1 && data_stock_list.length > 0"
      class="text-center pa-2 ma-0"
      @click="changePage_lotStock()"
    >
      <v-col cols="12" class="pa-0">
        <v-pagination
          v-model="page_Stock"
          :length="pageLength_Stock"
          :total-visible="7"
          color="primary"
        ></v-pagination>
      </v-col>
    </v-row>
    <div class="v-card-bottom-30" />
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import Loading from "@/website/components/Loading";
import { stockService_dotnet, shopService_dotnet } from "@/website/global";
import { AlertSuccess, AlertWarning, AlertError } from "@/website/global_alert";
import { formatMoney, GenIE, Trim_value, formatDate } from "@/website/global_function";
export default {
  components: {
    Loading,
  },
  data: () => ({
    // page
    header_token: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("authorization_token"),
    },
    page_loading: true,
    loading: false,
    progress_loading: false,

    // แสดงภาพ
    ShowPhoto: false,
    ShowPhotoLink: "",

    // ค้นหา
    search: {
      shopList: [{ id: 0, name: "--ทั้งหมด--" }],
      shop: 0,
      staffName: null,
      name: null,
      sku: null,
      stockType: 1,
      date_from: null,
      date_to: null,
    },

    // Export Excel
    loadingExcel: 0,
    datasetExcel: [],
    headerExcel: [
      { label: "รูป", field: "photoLink", width: 10 },
      { label: "ชื่อสินค้า", field: "productName", width: 50 },
      { label: "รหัสสินค้า", field: "productCode", width: 30 },
      { label: "SKU", field: "sku", width: 30 },
      { label: "Barcode", field: "barcode", width: 30 },
      { label: "จำนวนในระบบ", field: "systemQuantity", width: 30 },
      { label: "จำนวนเช็คจริง", field: "realQuantity", width: 30 },
      { label: "ส่วนต่าง", field: "diffQuantity", width: 30 },
      { label: "หมายเหตุ", field: "remark", width: 30 },
      { label: "ชื่อพนักงาน", field: "staffBranchName", width: 30 },
      { label: "วันที่สร้าง", field: "created", width: 40 },
      { label: "ร้านค้า", field: "shopName", width: 100 },
    ],

    // แสดงตาราง
    dataStock_sum: 0,
    data_stock_list: [],
    header: [
      {
        text: "No",
        align: "center",
        sortable: false,
        value: "no",
        icon: 0,
        width: "60px",
      },
      {
        text: "วันที่",
        align: "center",
        sortable: false,
        value: "created",
        icon: 0,
        width: "150px",
      },
      {
        text: "รูป",
        align: "left",
        sortable: false,
        value: "photoLink",
        icon: 0,
        width: "100px",
      },
      {
        text: "รายละเอียดสินค้า",
        align: "left",
        sortable: false,
        value: "name",
        icon: 0,
      },
      {
        text: "จำนวนในระบบ",
        align: "right",
        sortable: false,
        value: "systemQuantity",
        icon: 0,
        width: "120px",
      },
      {
        text: "จำนวนเช็คจริง",
        align: "right",
        sortable: false,
        value: "realQuantity",
        icon: 0,
        width: "120px",
      },
      {
        text: "ส่วนต่าง",
        align: "right",
        sortable: false,
        value: "diffQuantity",
        icon: 0,
        width: "120px",
      },
      {
        text: "ชื่อพนักงาน",
        align: "left",
        sortable: false,
        value: "staffBranchName",
        icon: 0,
      },
      {
        text: "หมายเหตุ",
        align: "center",
        sortable: false,
        value: "remark",
        icon: 0,
        width: "110px",
      },
      { text: "ร้านค้า", align: "left", sortable: false, value: "shopName", icon: 0 },
    ],

    // paginations location
    isPage_Stock: 0,
    page_Stock: 1,
    page_tmp_Stock: null,
    limits_Stock: 50,
    offsets_Stock: 0,
    pageLength_Stock: null,
  }),
  computed: {
    computed_search_date_to() {
      return this.formatDates(this.search.date_to);
    },

    computed_search_date_from() {
      return this.formatDates(this.search.date_from);
    },
  },
  watch: {},
  async created() {
    document.title = this.$router.history.current.meta.title;
    this.permission = JSON.parse(localStorage.getItem("permission"));

    await this.GetShop();
    this.load_data();

    this.page_loading = false;
  },
  methods: {
    async GetShop() {
      let response = await axios.post(
        shopService_dotnet + "Shop/get-shop-list",
        {
          branchId: localStorage.getItem("Branch_BranchID"),
        },
        { headers: this.header_token }
      );
      var shopList = response.data.result;
      if (response.status == 200 && shopList.length > 0) {
        for (var i in shopList) {
          this.search.shopList.push({
            id: shopList[i].shop.id,
            name: shopList[i].shop.name,
          });
        }
      }
    },

    formatDates(date) {
      if (date == null) {
        return null;
      } else {
        const [year, month, day] = date.split("-");
        return `${day}-${month}-${year}`;
      }
    },

    async load_data() {
      this.loading = true;
      try {
        let response = await axios.post(
          stockService_dotnet + "Stock/search-stock-checking-list",
          {
            branchID: localStorage.getItem("Branch_BranchID"),
            shopID: this.search.shop == 0 ? 0 : this.search.shop,
            productName: this.search.name,
            sku: this.search.sku,
            barcode: this.search.barcode,
            staffName: this.search.staffName,
            quantityCriteria: this.search.stockType,
            skip: this.offsets_Stock,
            take: this.limits_Stock,
            dateFrom: !this.search.date_from
              ? null
              : this.search.date_from + "T00:00:00.000Z",
            dateTo: !this.search.date_to ? null : this.search.date_to + "T00:00:00.000Z",
          },
          { headers: this.header_token }
        );

        var data_res = response.data;
        this.search.date_from = data_res.dateFrom.substring(0, 10);
        this.search.date_to = data_res.dateTo.substring(0, 10);
        this.data_stock_list = [];
        this.dataStock_sum = 0;
        if (response.data.productList != null) {
          this.dataStock_sum = response.data.resultCount;
          this.data_stock_list = JSON.parse(JSON.stringify(response.data.productList));
        }
        this.getpagelength_Stock(response.data.resultCount);
        this.loading = false;
      } catch (e) {
        AlertError(e.message);
        this.loading = false;
      }
    },

    set_format_date_time(val) {
      if (val != null) {
        var date_tmp = val.substring(0, 10);
        const date_split = date_tmp.split("-");
        var time_tmp = val.substring(11, 19);
        var set_dattime = date_split[2] + "-" + date_split[1] + "-" + date_split[0] + " "; //+ time_tmp
        return set_dattime;
      } else {
        return "";
      }
    },

    set_format_date_time2(val) {
      if (val != null) {
        var date_tmp = val.substring(0, 10);
        const date_split = date_tmp.split("-");
        var time_tmp = val.substring(11, 19);
        var set_dattime =
          date_split[2] + "-" + date_split[1] + "-" + date_split[0] + " " + time_tmp;
        return set_dattime;
      } else {
        return "";
      }
    },

    set_format_date_time_for_excel(val) {
      if (val != null) {
        var date_tmp = val.substring(0, 10);
        const date_split = date_tmp.split("-");
        var time_tmp = val.substring(11, 19);
        var set_dattime = date_split[0] + "-" + date_split[1] + "-" + date_split[2]; //+ time_tmp
        return set_dattime;
      } else {
        return null;
      }
    },

    getpagelength_Stock(total_count) {
      var total = 0;
      if (total_count != 0) {
        total = total_count;
      } else {
        total = 1;
      }

      total = parseInt(total);
      this.pageLength_Stock = Math.ceil(total / this.limits_Stock);
      this.isPage_Stock = 0;
      this.page_tmp_Stock = this.page_Stock;
    },

    async changePage_lotStock() {
      this.loading = true;
      this.offsets_Stock = this.page_Stock * this.limits_Stock - this.limits_Stock;

      if (this.page_Stock > 1 && this.page_Stock < this.pageLength_Stock) {
        if (this.page_tmp_Stock != this.page_Stock) {
          await this.load_data();
          this.page_tmp_Stock = this.page_Stock;
        }
      } else if ((this.page_Stock == 1, this.pageLength_Stock)) {
        if (this.page_tmp_Stock != this.page_Stock) {
          await this.load_data();
          this.page_tmp_Stock = this.page_Stock;
        }
      }
      this.loading = false;
    },

    gen_num_data_table(index, sum_data, offsets) {
      if (index != null && this.loading == false) {
        var start = null;
        start = sum_data - offsets;
        return formatMoney(start - index);
      } else {
        return "";
      }
    },

    async ExportExcel(search) {
      this.datasetExcel = [];
      this.loadingExcel = 0;
      Swal.fire({
        position: "top",
        icon: "warning",
        text: "ใช้เวลาสักครู่กรุณารอจนกว่าจะเสร็จ",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.value) {
          var limits = 1000;
          var offsets = 0;
          this.loadingExcel = 1;
          var total = this.dataStock_sum;
          var round = parseInt(total / limits);

          if (limits * round < total) {
            round = round + 1;
          }

          var current = 0;
          for (var i = 1; i <= round; i++) {
            var data = await this.GetExportExcelProduct(limits, offsets);
            for (var j in data) {
              data[j].created = this.set_format_date_time2(data[j].created);
              this.datasetExcel.push(data[j]);
              current += 1;
            }
            this.loadingExcel = parseInt(
              (parseFloat(i) / parseFloat(round)) * parseFloat(100)
            );
            offsets += limits;
          }

          for (var d in this.datasetExcel) {
            this.datasetExcel[d]["no"] = this.datasetExcel.length - parseInt(d);
          }

          this.loadingExcel = this.datasetExcel.length == 0 ? 0 : 100;
          document.getElementById("GetExcel").click();
        }
      });
    },
    async GetExportExcelProduct(limits, offsets) {
      try {
        let response = await axios.post(
          stockService_dotnet + "Stock/search-stock-checking-list",
          {
            branchID: localStorage.getItem("Branch_BranchID"),
            shopID: this.search.shop == 0 ? 0 : this.search.shop,
            productName: this.search.name,
            sku: this.search.sku,
            barcode: this.search.barcode,
            staffName: this.search.staffName,
            quantityCriteria: this.search.stockType,
            skip: offsets,
            take: limits,
            dateFrom: !this.search.date_from
              ? null
              : this.search.date_from + "T00:00:00.000Z",
            dateTo: !this.search.date_to ? null : this.search.date_to + "T00:00:00.000Z",
          },
          { headers: this.header_token }
        );
        if (response.data.productList != null) {
          return response.data.productList;
        } else {
          return [];
        }
      } catch (e) {
        AlertError(e.message);
        this.loading = false;
      }
    },

    OpenPhoto(Photo) {
      this.ShowPhotoLink = Photo;
      this.ShowPhoto = true;
    },
    Opentap(PhotoLink) {
      window.open(PhotoLink);
    },

    gotoStock_InFo(item) {
      window.open("stock-info?id=" + item.stockShopID);
    },

    allowedDates: (val) => val <= new Date().toISOString().substr(0, 10),
    formatMoney,
    Trim_value,
    AlertSuccess,
    AlertWarning,
    AlertError,
    formatDate,
    GenIE,
  },
};
</script>

<style scoped>
>>> .v-select__slot {
  height: 40px;
}
.cut-text {
  text-decoration: none;
  text-overflow: ellipsis; /* เพิ่ม ... จุดจุดจุดท้ายสุด */
  display: block;
  overflow: hidden;
  white-space: nowrap;
  width: 80%; /* กำหนดความกว้าง */
}

.sort-bar {
  font-weight: 400;
  background: rgba(0, 0, 0, 0.03);
  padding: 0.8125rem 1.25rem;
  border-radius: 2px;
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
}

.w-200P {
  width: 200px;
}
.p-15 {
  padding: 0px 0px 0px 15px;
}
.w-13r {
  width: 13rem;
}

>>> .v-dialog {
  overflow-y: hidden;
  -webkit-box-shadow: none;
  box-shadow: none;
}
</style>
